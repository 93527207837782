<template>
  <div class="background">
    <SuccessfulPayment />
  </div>
</template>

<script>
import SuccessfulPayment from "@/components/elements/dashboard/payment/successful-payment/SuccessfulPayment";

export default {
  name: "DocumentManagementSuccessfulPayment",

  components: {
    SuccessfulPayment,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
